import React, { useState } from "react";

function App() {
  const [word, setWord] = useState();

  return (
    <main className="flex min-h-screen items-center justify-center p-5 sm:p-10">
      <div className="space-y-4 max-w-lg text-right">
        {word === "כובע" ? (
          <div className="space-y-10">
            <div>
              <h1 className="text-center text-xl pb-5 font-serif">2024</h1>
              <img src="/dachshund.webp" alt="Dachshund" />
              <h1 className="font-bold text-center !my-5">לאחינו מינו</h1>
              <p>שוב אנחנו נפגשים כאן בפורטל האחים.</p>
              <p>
                כמנהג המסורת, ולכבוד יום הולדתך ה21, אשא דברים ואאחל איחולים.
              </p>
              <p>
                בעשור הקרוב דבר אחד יעשה את ההבדל יותר מהכל, והוא ההבנה ש
                <b>תקופות לא מקבלים בחזרה</b>. בין אם את למעלה או למטה, בטוב או
                ברע, אאחל לך תמיד להצליח לקבל את התקופה בידיים פתוחות ובאהבה.
              </p>
              <p>
                זכרי שחוויות משמעותיות הן תוצאתן של תעוזה ופעולה, ושהן לא מגיעות
                מעצמן. אם תרצי שנה מרגשת, אאחל לך להעז, להסתכן, ולהצליח, כי אין
                זמן טוב יותר מעכשיו.
              </p>
              <p>
                אאחל לך שנה שתציג לך את העולם באור אחר שלא הכרת ולא ידעת אהבת.
                שנה שתהיה מלאה בחוויות חיוביות, בונות, ובלתי נשכחות.
              </p>
              <p>
                אאחל לך לגלות בעצמך צדדים חדשים, להתגבר על פחדים, ולהפוך חלומות
                למציאות. שתכניות על דף יקומו לתחיה ושתמונות בראש יצטיירו מול
                ענייך.
              </p>
              <p>
                אאחל לך אין סוף בריאות (כי בלי זה אין כלום), הגשמה (כי בלי זה
                אין אושר), ואהבה (כי בלי האנשים סביבך כלום לא משנה).{" "}
              </p>
              <p>
                זכרי תמיד שאני כאן בשבילך, תומך ומעודד מקרוב ומרחוק, ושגם אם אני
                לא תמיד ליד את תמיד נמצאת בלבי.
              </p>
              <p>שהמזל תמיד יהיה לצדך, ושיהיה לך יום הולדת מאוד שמח.</p>
              <p>אוהב אותך המון, אחינו ♥</p>
            </div>
            <div>
              <h1 className="text-center text-xl pb-5 font-serif">2023</h1>
              <img src="/ahim.png" alt="Ahim" />
              <h1 className="font-bold text-center !my-5">ברוך הבא, אחי.</h1>
              <p>
                הגעת לפורטל האחים. פורטל האחים הינו מקום סודי באינטרנט בו נוכל
                להיות מפגרים מתחת לאף של כולם מבלי שאף אחד יוכל לדעת.
              </p>
              <p>
                פורטל האחים נולד מתוך ההבנה והמסקנה שלנו כאחים צריך להיות פורטל.
              </p>
              <p>הפורטל נמצא כרגע בשלבי פיתוח ולכן אין בו שום דבר.</p>
              <p>
                אם כן, בנימה זו, ולכבוד יום הולדתך ה20, אנצל את מעמד חניכת
                הפורטל כדי לאחל לך מספר דברים מעומק לבי.
              </p>
              <p>
                קודם כל, זכרי שכל אחד ואחד מאיתנו מעביר חצי מחייו במצב רוחי
                ורגשי רע מהממוצע האישי שלו וחצי במצב טוב. מעבר למובן מאליו בו
                אני מאחל לך שהממוצע שלך יהיה גבוה ככל האפשר, אני מאחל לך את
                היכולת להתמודד עם כל מכשול רגשי או ממשי בו תתיקלי במהלך חייך
                ובעיקר בעשור הקרוב.
              </p>
              <p>
                אני מאחל לך עשור של חוויות, גילויים, זכרונות, מסקנות, והצלחות.
              </p>
              <p>
                אני מאחל לך שיפול עלייך כסף מהשמיים, שתאהבי כל פיסת קולנוע בה
                תצפי, שהמזל הטוב ירדוף אחרייך לאן שתלכי, ושבו בזמן תחיי חיים
                מאתגרים ומעניינים, כי אין אור בלי חושך ואין חושך בלי אור.
              </p>
              <p>
                השנים הקרובות הולכות להיות משמעותיות ביותר עבורך, ואני מאחל לך
                את היכולת למצוא את הדרך להעביר אותן בשלווה, סיפוק, שמחה,
                וביטחון.
              </p>
              <p>אני תמיד אהיה כאן בשבילך כדי לעזור לזה לקרות.</p>
              <p>אוהב אותך המון, אחינו ♥</p>
              <img src="/beaver.png" alt="Beaver" className="!mt-10" />
            </div>
          </div>
        ) : (
          <>
            <div className="font-bold">שלום, אחי.</div>
            <div>
              לכניסה יש לענות על החידה: <br />
              מהי המילה החמישית?
            </div>
            <input
              type="text"
              className="border-b-2 text-center leading-8"
              value={word}
              onChange={(e) => setWord(e.target.value.trim())}
              autoFocus
            />
          </>
        )}
      </div>
    </main>
  );
}

export default App;
